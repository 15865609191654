.sommaire {
  padding: 2.5rem 0;
  background-color: #{$primary-background-color};
  position: relative;
  z-index: 1;
  &::before {
    width: 100%;
    height: 100%;
    content:'';
    background-color: #{$primary-background-color};
    position: absolute;
    top: 0;
    left: -70%;
    z-index: -1;
  }
  &::after {
    width: 100%;
    height: 100%;
    content: '';
    background-color: #{$primary-background-color};
    position: absolute;
    top: 0;
    right: -70%;
    z-index: -1;
  }
  ol {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    margin-left: 1.25rem;
    li {
      padding: 1rem 0;
      text-decoration: underline;
      font-size: 1rem;
      font-weight: 700;
      &::marker {
        margin-right: .25rem;
        font-size: 1.125rem;
        font-weight: 700;
      }
      a {
        margin-left: .75rem;
      }
    }
    @media (min-width: 902px) {
      max-height: 250px;
    }
  }
  @media (min-width: 902px) {
    padding: 3rem 0;
  }
}