@mixin img-size-cover($size) {
  display: block;
  width: auto !important;
  max-width: none !important;
  min-width: 1000%;
  height: auto;
  min-height: 1000%;
  margin: auto;
  position: absolute;
  top: -10000%;
  right: -10000%;
  bottom: -10000%;
  left: -10000%;
  transform: scale($size / 1000);
}

.card {
  margin: $element-top-margin 0 $element-bottom-margin 0;
  border-radius: #{$card-border-radius};
  background-color: $card-bg-color;
  @extend .z-depth-1;
  transition: box-shadow .25s;
  overflow: hidden;
  position: relative;

  &.no-radius {
    border-radius: 0;
  }

  &.no-background {
    background: none;
  }

  .card-title {
    margin-bottom: .5em;
    font-size: 1.5rem;
    font-weight: 700;
    &.default {
      font-size: 1rem;
      font-weight: 400;
    }
  }

  .card-image {
    a {
      display: block;
      width: auto;
      height: auto;
    }
    img {
      display: block;
      width: 100%;
      height: auto;
    }
    &.square {
      display: block;
      width: 100%;
      height: auto;
      padding-bottom: 100%;
      overflow: hidden;
      position: relative;
      a {
        width: 100%;
        height: 100%;
        position: absolute;
      }
      img {
        @include img-size-cover(100);
      }
    }
  }

  &.horizontal {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    .card-image {
      width: 100%;
      min-width: 120px;
      max-width: 50%;
      position: relative;
      overflow: hidden;
      a {
        display: block;
        width: 100%;
        height: 100%;
        position: relative;
        overflow: hidden;
      }
      img {
        @include img-size-cover(100);
      }
    }
    .card-content {
      flex-grow: 1;
    }
  }

  .card-content {
    padding: $card-padding;

    p {
      font-size: small;
      margin: 0;
      color: #{$text-color};
    }
  }

  &.link-cover {
    .card-content {
      position: static;
      a {
        &::after {
          display: block;
          width: 100%;
          height: 100%;
          content: '';
          background: none;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
        }
      }
    }
    .card-title {
      position: static;
      a {
        &::after {
          display: block;
          width: 100%;
          height: 100%;
          content: '';
          background: none;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
        }
      }
    }
    &.primary-text,
    .primary-text {
      &:hover {
        color: #{$primary-color-darken};
      }
    }
    &.secondary-text,
    .secondary-text {
      &:hover {
        color: #{$secondary-color-darken};
      }
    }
    &.accent-text,
    .accent-text {
      &:hover {
        color: #{$accent-color-darken};
      }
    }
    &:hover {
      .primary-text {
          color: #{$primary-color-darken};
      }
      .secondary-text {
        color: #{$secondary-color-darken};
      }
      .accent-text {
        color: #{$accent-color-darken};
      }
    }
  }

  &.full-image {
    position: relative;
    .card-image {
      &::before {
        display: block;
        width: 100%;
        height: 100%;
        content: '';
        background-image: linear-gradient(to bottom, transparent 0%, #000000 100%);
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: .64;
        transition: opacity .3s ease-in-out;
      }
      .card-title {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin: 0;
        padding: $card-padding;
        color: #FFFFFF !important;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        a {
          color: #FFFFFF !important;
          &::after {
            display: block;
            width: 100%;
            height: 100%;
            content: '';
            background: none;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
          }
        }
      }
      &.square {
        a {
          width: auto;
          height: auto;
          position: static;
        }
        &::before {
          z-index: 1;
        }
        .card-title {
          z-index: 2;
        }
      }
      &:hover {
        &::before {
          opacity: 1;
        }
      }
    }
    &.primary {
      .card-image {
        &::before {
          background-image: linear-gradient(to bottom, transparent 0%, #{$primary-color} 100%);
        }
      }
    }
    &.secondary {
      .card-image {
        &::before {
          background-image: linear-gradient(to bottom, transparent 0%, #{$secondary-color} 100%);
        }
      }
    }
    &.accent {
      .card-image {
        &::before {
          background-image: linear-gradient(to bottom, transparent 0%, #{$accent-color} 100%);
        }
      }
    }
  }
}