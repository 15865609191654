.menu-mobile {
  height: 40px;
  background-color: #{$background-color};
  position: fixed;
  top: 0; right: 0; left: 0;
  z-index: 10;
  .container { 
    height: 100%;
    .menu-mobile-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      .btn-menu {
        border: none;
        background: none;
        color: #{$text-color};
        transition: color .3s;
        &:hover {
          color: #{$primary-color};
        }
      }
    }
  }
  @media #{$large-and-up} {
    display: none;
  }
}

#nav-meatballs {
  position: fixed; 
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  padding-top: 40px;
  background: #FFFFFF;
  transition: left .3s;
  overflow-y: scroll;

  .section {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .tel {
    display: inline-block;
    margin-bottom: .5rem;
  }

  .title-6 {
    margin-bottom: 1rem;
    [class*="material-icons"] {
      margin-right: 1rem;
    }
  }

  @media #{$medium-and-up} {
    text-align: center;
    .horaires {
      text-align: left;
      margin: 0 auto;
    }
    .reseaux-sociaux {
      ul {
        justify-content: center;
      }
    }
  }
}

.top-bar {
  @media #{$medium-and-down} {
    #infos-header {
      display: none;
    }
  }
  .main-nav {
    width: 100%;
    height: 100%;
    margin-top: 40px;
    padding-top: 1.5rem;
    background-color: #{$primary-color-darken};
    overflow-y: auto;
    position: fixed;
    top: 0;
    right: -100%;
    z-index: 1;
    transition: right .3s;
    > ul {
      width: 90%;
      text-align: right;
    }
    ul {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin: 0 auto;
      li {
        padding: 0;
        list-style: none !important;
        &:last-child {
          padding-bottom: 0;
        }
        a {
          display: block;
          padding: {
            top: .5rem;
            bottom: .5rem;
          }
          color: #FFFFFF;
          &.selected {
            opacity: .48;
          }
        }
        &:hover {
          > a {
            opacity: .48;
          }
        }
        &.sous_menu {
          > a {
            &::after {
              display: inline-block;
              margin: -.125em 0 0 .25em;
              content: '\e5cf';
              font-family: 'Material Icons';
              line-height: 0;
              vertical-align: middle;
            }
          }
          ul.dropdown {
            padding-bottom: .5rem;
            > li {
              > a {
                font-size: .875em;
                text-align: right;
              }
            }
          }
        }
      }
    }
    @media #{$large-and-up} {
      margin: 0;
      padding: 0;
      background-color: transparent;
      overflow: unset;
      position: relative;
      right: 0;
      z-index: 1;
      transition: none;
      ul {
        flex-direction: row;
        justify-content: flex-end;
        align-items: baseline;
        width: auto;
        margin: {
          right: (-1 * $gutter-width / 2);
          left: (-1 * $gutter-width / 2);
        }
        li {
            padding: 0;
            position: relative;
          a {
            padding: {
              top: 1rem;
              right: $gutter-width / 2;
              bottom: 1rem;
              left: $gutter-width / 2;
            }
            font-size: 1em;
            line-height: 1rem;
            color: inherit;
            opacity: 1 !important;
            &.selected {
              color: #{$primary-color};
            }
          }
          &:hover {
            >
            a {
                color: #{$primary-color};
            }
          }
          &.sous_menu {
            >
              a {
                &::after {
                  display: inline-block;
                  margin: -.125em 0 0 .25em;
                  content: '\e5cf';
                  font-family: 'Material Icons';
                  line-height: 0;
                  vertical-align: middle;
                }
              }
            ul.dropdown {
              display: none;
              flex-direction: column;
              align-items: stretch;
              width: max-content;
              min-width: 100%;
              max-width: 320px;
              margin: 0;
              padding: 0;
              background-color: #{$background-color};
              position: absolute;
              left: 0;
            
              li {
                a {
                  text-align: left;
                  padding: {
                    top: .5rem;
                    bottom: .5rem;
                    
                  }
                  font-size: .875em;
                }
              }
            }
            &:hover {
              ul.dropdown {
                display: flex;
              }
            }
          }
        }
      }
    }
  }
}