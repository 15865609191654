#encart-actualites {
  ul:not(.slick-dots) {
    @extend .row;
    margin-bottom: 2rem;
    > li,
    > .slick-list > .slick-track > li {
      @extend .col;
      @extend .s12;
      &:not(:first-child) {
        @extend .m6;
        @extend .l4;
      }
      @extend .l4;
      .card {
        @extend .z-depth-0;
        @extend .no-background;
        @extend .no-radius;
        .card-title {
          @extend .secondary-text;
        }
      }
    }
  }
}