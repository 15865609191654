.reseaux-sociaux {
  padding: 0;
  width: auto;

  &.black-text {
    svg {
      fill: #000000;
    }
  }
  .white-text &,
  &.white-text {
    svg {
      fill: #ffffff;
    }
  }

  ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: {
      left: (-1 * $gutter-width /3);
      right: (-1 * $gutter-width /3);
    }
    li {
      margin: 0;
      padding: {
        left: $gutter-width /3;
        right: $gutter-width /3;
      }
      list-style: none !important;

      a {
        display: block;
        text-decoration: none;
        transition: transform .3s ease-in-out;
        &:hover {
          transform: scale(1.1);
        }
        svg {
          display: block;
        }
      }
    }
  }
}