.page-404 {
    #img-404 {
        font-size: 8rem;
        @media screen and (min-width: 401px) {
            font-size: 10rem;
        }
        @media #{$medium-and-up} {
            font-size: 8rem;
        }
        @media #{$large-and-up} {
            font-size: 12rem;
        }
    }
    .btn-contact {
        margin-top: 1.5rem;
    }
}