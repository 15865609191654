.slider-slick {
    width: 100%;
    height: 500px;
    background-color: #{$background-color};
    position: relative;
    z-index: 0;
    .slick {
        height: 100%;
    }
    @media #{$small-and-down} {
        display: none;
    }
}
.slide-img {
    min-height: 500px;
    background-size: cover;
    background-position: center;
    &.slide-01 {
        background-image: #{$img-slide-01};
    }
    &.slide-02 {
        background-image: #{$img-slide-02};
    }
    &.slide-03 {
        background-image: #{$img-slide-03};
    }
    .container {
        min-height: 500px;
        .bloc {
            height: 500px;
        }
    }
}