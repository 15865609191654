.sc-content {
  // Grille
  // =============================================================
  .ac-doubleColonne {
    margin: {
      left: (-1 * $gutter-width / 2);
      right: (-1 * $gutter-width / 2);
    }
    padding: {
      top: 2rem;
      bottom: 2rem;
    }
    .ac-colonne {
      box-sizing: border-box;
      padding: 0 $gutter-width / 2;
      min-height: 1px;
    }
    @media #{$medium-and-up} {
      display: flex;
      flex: {
        direction: row;
        wrap: wrap;
      }
      align-items: stretch;
      &.reverse {
        flex-direction: row-reverse;
      }
      .ac-colonne {
        width: 50%;
      }
    }
  }

  // Images
  // =============================================================
  img {
    display: block;
    max-width: 100%;
    height: auto;
    margin: 0 auto;
  }
  /*
  .image-wrapper {
    display: block;
    width: 100%;
    height: auto;
    position: relative;
    overflow: hidden;
    &::after {
      display: block;
      width: 100%;
      height: 0 !important;
      padding-bottom: 56.25%;
      content: '';
    }
    img {
      display: block;
      width: auto;
      max-width: none;
      min-width: 1000%;
      height: auto;
      min-height: 1000%;
      margin: auto;
      position: absolute;
      top: -10000%;
      right: -10000%;
      bottom: -10000%;
      left: -10000%;
      transform: scale(.1);
    }
    @media #{$medium-and-up} {
      height: 100%;
      &::after {
        display: none;
        content: none;
      }
    }
  }
  */

  // Liens de relance
  // =============================================================
  #relance {
    margin: {
      top: 2rem;
      bottom: 4rem;
    }
    text-align: center;
    p.titre-relance {
      font: {
        size: 1rem;
        weight: 700;
      }
    }
    ul {
      margin: {
        left: 0 !important;
      }
      li {
        list-style: none !important;
        a {
          text-decoration: underline;
          color: #{$secondary-color};
          &:hover {
            text-decoration: none;
          }
        }
      }
      @media #{$medium-and-up} {
        display: flex;
        flex: {
          direction: row;
          wrap: wrap;
        }
        justify-content: center;
        align-items: baseline;
        width: 100%;
        margin: {
          left: (-1 * $gutter-width / 2);
          right: (-1 * $gutter-width / 2);
        }
        li {
          margin: 0;
          padding: 0 $gutter-width / 2;
        }
      }
    }
  }
}

// @import "sommaire";